<template>
  <div v-if="!loading">
    <v-container>
      <v-row v-if="!adding">
        <v-col cols="12" v-if="!addresses || addresses.length == 0">
          <h5 class="fs-16" v-if="isChef">
            <v-icon color="red">warning</v-icon>
            {{ $t('Common.address_help_empty_chef') }}
          </h5>
          <h5 v-else>
            <v-icon color="red">warning</v-icon>
            {{ $t('Common.address_help_empty') }}
          </h5>
        </v-col>
        <v-col cols="12" sm="2">
          <v-btn
            v-show="!editing && addresses.length < 4"
            @click="addAddress"
            color="success"
          >Add Address</v-btn>
          <!-- address type counts is 4  -->
        </v-col>
        <v-col cols="12" sm="2" v-if="allowSetCurrentAddress">
          <v-btn
            :to="profile.id ? '/address/current/' + profile.id : '/address/current'"
            color="info"
          >Set current address</v-btn>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="9" lg="6">
          <base-address
            v-if="adding || editing"
            :mode="mode"
            :index_no="index_no"
            :addressType="addresses[selectedIndex] ? addresses[selectedIndex].type : ''"
            :selectedAddress="addresses[selectedIndex]"
            :addresses="addresses"
            @editAddress="editAddress"
            @saveAddress="saveAddress"
            :isChef="isChef"
          ></base-address>
        </v-col>
      </v-row>

      <v-row>
        <template v-for="(address, index) in addresses">
          <v-col sm="12" md="6" :key="index" class="pr-1 pb-1">
            <address-view
              :address="address"
              @editAddress="editAddress"
              @removeAddress="removeAddress"
              :index="index"
              :isChef="isChef"
            ></address-view>
          </v-col>
        </template>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import BaseAddress from "./BaseAddress";
import AddressView from "./AddressView";
import { mapGetters } from "vuex";

export default {
  components: {
    BaseAddress,
    AddressView
  },
  props: ["profile"],
  data() {
    return {
      index_no: 0,
      mode: "add",
      adding: false,
      editing: false,
      selectedIndex: 0,
      addresses: [],
      currentProfile: null
    };
  },

  mounted() {
    this.loadCurrentProfileAddress();
  },
  computed: {
    ...mapGetters({
      isChef: "isChef",
      loading: "loading"
    }),
    allowSetCurrentAddress() {
      if (!this.profile) {
        return false;
      }

      if (this.profile.permits) {
        let ok = this.profile.permits.filter(
          p =>
            p.type &&
            p.type.text &&
            (p.type.text.indexOf("Truck") >= 0 ||
              p.type.text.indexOf("MFF") >= 0 ||
              p.type.text.indexOf("Catering") >= 0)
        );
        return ok.length > 0;
      }
      return false;
    }
  },
  methods: {
    loadCurrentProfileAddress() {
      if (this.profile) {
        this.addresses = this.profile.addresses || [];
        if (this.addresses.length > 0) {
          for (let i = 0; i < this.addresses.length; i++) {
            this.addresses[i].no = i;
          }

          this.index_no = this.addresses[this.addresses.length - 1].no + 1;
        }
      }
    },
    addAddress() {
      this.mode = "add";
      this.adding = !this.adding;
      this.editing = false;
      this.address = {};
    },
    saveAddress(address, mode) {
      this.mode = "add";
      this.editing = false;
      var geocoder = new google.maps.Geocoder();

      var address_str = `${address.address1 || ""} ${address.city ||
        ""} ${address.state || ""} ${address.zipcode || ""}`;

      //address_str = address_str.replace(/ /g, '')
      let addresses = this.addresses ? this.addresses : [];

      if (address_str) {
        const _store = this.$store;
        
        const profile = this.profile

        geocoder.geocode({ address: address_str }, function(results, status) {
          if (status == google.maps.GeocoderStatus.OK) {
            var latitude = results[0].geometry.location.lat();
            var longitude = results[0].geometry.location.lng();

            if (latitude) {
              address.latitude = latitude;
              address.longitude = longitude;
              if (address.location) {
                address.location._lat = latitude;
                address.location._long = longitude;
              }
              if (results[0].formatted_address) {
                address.formatted_address = results[0].formatted_address;
              }
              console.log("Geocoder service address", address);
              if (mode === "add") {
                addresses.push(Object.assign({}, address));
              } else {
                for (let i = 0; i < addresses.length; i++) {
                  if (addresses[i].no === address.no) {
                    addresses[i] = address;
                    break;
                  }
                }
              }
              if (profile){
                console.error('Profile is empty')
              }              
              _store.dispatch("saveAddresses", {
                profile: profile,
                addresses: addresses
              });
              //_store.dispatch('loadProfile')
            } else {
              console.warn("Unable to get Geocoder service address");
            }
          } else {
            console.warn("Unable to get Geocoder service address");
          }
        });
        this.adding = !this.adding;
      } else {
        if (mode === "add") {
          this.addresses.push(Object.assign({}, address));
          //this.$store.dispatch('loadProfile')
        } else {
          //edit mode
          for (let i = 0; i < this.addresses.length; i++) {
            if (this.addresses[i].no === address.no) {
              this.addresses[i] = address;
              break;
            }
          }
        }
        console.log('----------, this2:', this.profile)
        this.$store.dispatch("saveAddresses", {
          profile: this.profile,
          addresses: this.addresses
        });
      }
    },
    editAddress(index) {
      this.$vuetify.goTo(0);
      this.mode = "edit";
      this.editing = true;
      this.selectedIndex = index;
      this.index_no = index;
      // console.log(this.addresses, 'this.addresses')
    },
    removeAddress(index) {
      this.editing = false;
      this.addresses.splice(index, 1);
      this.$store.dispatch("saveAddresses", {
        profile: this.profile,
        addresses: this.addresses
      });
    }
  }
};
</script>
<style scoped>
.location_city {
  padding: 1em;
  width: 80%;
}
</style>
